
import { defineComponent, ref, onMounted, watch, handleError } from "vue";
import { getCustomersList } from "@/store/api/devices";
import {
  addNotificationRule,
  getEmailRules,
  updateNotification,
} from "@/store/api/devices";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
interface IPagination {
  page_number: number;
  page_size: number;
  customer_id: string;
  filters: {
    name: string;
  };
}
export default defineComponent({
  name: "repository",
  components: {},
  props: {
    data: {
      type: Object,
      required: true,
    },
    loadData: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const notification = ref<any[]>([]);
    const customers = ref<any[]>([]);
    const selectedValue = ref<any[]>([]);
    const notification_rule = ref<any>(props.data);
    const total = ref<number>(0);
    let loading = ref(false);
    const closeModal = ref<null | HTMLElement>(null);
    const payload = ref({
      se_account_id: "",
      enable_temperature_notification: "",
      temperature_operator: "0",
      temperature_value: "",
      enable_battery_notification: "",
      battery_operator: "0",
      battery_value: "",
      enable_fill_notification: "",
      fill_operator: "0",
      fill_value: "",
      enable_error_notification: "0",
      enable_offline_notification: "0",
      enable_inactive_notification: "0",
      enable_fire_alarm: "0",
      enable_fire_alarm_sms: "0",
      enable_pickup_event: "0",
      enable_pickup_event_sms: "0",
      enable_temper_event: "0",
      enable_temper_event_sms: "0",
    });

    const checkform = ref({
      se_account_id: "",
      enable_temperature_notification: "",
      temperature_operator: "0",
      temperature_value: "",
      enable_battery_notification: "",
      battery_operator: "0",
      battery_value: "",
      enable_fill_notification: "",
      fill_operator: "0",
      fill_value: "",
      enable_error_notification: "0",
      enable_offline_notification: "0",
      enable_inactive_notification: "0",
      enable_fire_alarm: "0",
      enable_fire_alarm_sms: "0",
      enable_pickup_event: "0",
      enable_pickup_event_sms: "0",
      enable_temper_event: "0",
      enable_temper_event_sms: "0",
    });

    watch(
      () => props.data.id,
      (va) => {
        notification_rule.value = props.data;
        if (va) {
          getEmailRules(va).then((response) => {
            payload.value = response.data;
          });
        } else {
          payload.value = {
            se_account_id: "",
            enable_temperature_notification: "",
            temperature_operator: "0",
            temperature_value: "",
            enable_battery_notification: "",
            battery_operator: "0",
            battery_value: "",
            enable_fill_notification: "",
            fill_operator: "0",
            fill_value: "",
            enable_error_notification: "0",
            enable_offline_notification: "0",
            enable_inactive_notification: "0",
            enable_fire_alarm: "0",
            enable_fire_alarm_sms: "0",
            enable_pickup_event: "0",
            enable_pickup_event_sms: "0",
            enable_temper_event: "0",
            enable_temper_event_sms: "0",
          };
        }
      }
    );

    onMounted(async () => {
      customers.value = await getCustomersList();
    });
    const pagination = ref<IPagination>({
      page_number: 1,
      customer_id: "",
      page_size: 10,
      filters: {
        name: "",
      },
    });
    const modalClose = () => {
      notification_rule.value = {};
      payload.value = {
        se_account_id: "",
        enable_temperature_notification: "",
        temperature_operator: "0",
        temperature_value: "",
        enable_battery_notification: "",
        battery_operator: "0",
        battery_value: "",
        enable_fill_notification: "",
        fill_operator: "0",
        fill_value: "",
        enable_error_notification: "0",
        enable_offline_notification: "0",
        enable_inactive_notification: "0",
        enable_fire_alarm: "0",
        enable_fire_alarm_sms: "0",
        enable_pickup_event: "0",
        enable_pickup_event_sms: "0",
        enable_temper_event: "0",
        enable_temper_event_sms: "0",
      };
    };
    const handleClose = () => {
      notification_rule.value = {};
      payload.value = {
        se_account_id: "",
        enable_temperature_notification: "",
        temperature_operator: "0",
        temperature_value: "",
        enable_battery_notification: "",
        battery_operator: "0",
        battery_value: "",
        enable_fill_notification: "",
        fill_operator: "0",
        fill_value: "",
        enable_error_notification: "0",
        enable_offline_notification: "0",
        enable_inactive_notification: "0",
        enable_fire_alarm: "0",
        enable_fire_alarm_sms: "0",
        enable_pickup_event: "0",
        enable_pickup_event_sms: "0",
        enable_temper_event: "0",
        enable_temper_event_sms: "0",
      };
      loading.value = false;
      props.loadData();
      document.getElementById("addnewnotificationmodal")?.click();
    };

    const submit = async () => {
      const checkformWithoutAccountId = {
        ...checkform.value,
        se_account_id: undefined,
      };
      const payloadWithoutAccountId = {
        ...payload.value,
        se_account_id: undefined,
      };
      const checkformString = JSON.stringify(checkformWithoutAccountId);
      const payloadString = JSON.stringify(payloadWithoutAccountId);
      if (
        checkformString !== payloadString &&
        payload.value.se_account_id !== ""
      ) {
        if (notification_rule.value.id) {
          await updateNotification(notification_rule.value?.id, payload.value)
            .then((resp) => {
              loading.value = true;
              Swal.fire({
                text: "Updated Successfylly",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
              handleClose();
            })
            .catch((err) => {
              console.log(err);
              Swal.fire({
                icon: "error",
                title: `${err.response.data.message}`,
              });
              handleClose();
            });
        } else {
          await addNotificationRule(payload.value)
            .then((response) => {
              loading.value = true;
              Swal.fire({
                text: "Company has been successfully added!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
              handleClose();
            })
            .catch((err) => {
              console.log(err);
              Swal.fire({
                icon: "error",
                title: `${err.response.data.message}`,
              });
              handleClose();
            });
        }
      }
    };

    return {
      customers,
      selectedValue,
      payload,
      loading,
      closeModal,
      submit,
      notification,
      pagination,
      total,
      notification_rule,
      handleClose,
      modalClose,
    };
  },
});
