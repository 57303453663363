
import { defineComponent, ref, onMounted } from "vue";
import KTDatatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import {
  getCustomersList,
  getNotificationListing,
  deleteNotification,
  checkPassword,
} from "@/store/api/devices";
import EmailModal from "./modal/EmailModal.vue";
import SmsModal from "./modal/SmsModal.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import AddNotification from "./modal/AddNotification.vue";
interface IPagination {
  page_number: number;
  page_size: number;
  customer_id: string;
  filters: {
    name: string;
  };
}
export default defineComponent({
  name: "repository",
  components: {
    KTDatatable,
    EmailModal,
    SmsModal,
    AddNotification,
  },
  setup() {
    const notification = ref<any[]>([]);
    const customers = ref<any[]>([]);
    let data = ref<any>({ id: "", company_name: "" });
    const loading = ref<boolean>(true);
    const search = ref<string>("");
    const cardData = ref<string>("");
    const permissions = ref<any>("");
    const headerConfig = ref([
      {
        name: "Company Name",
        key: "company_name",
      },
      {
        name: "Fill Level",
        key: "enable_fill_notification",
      },
      {
        name: "Temperature",
        key: "enable_temperature_notification",
      },
      {
        name: "Battery",
        key: "enable_battery_notification",
      },
      {
        name: "511 error",
        key: "enable_error_notification",
      },
      {
        name: "offline",
        key: "enable_offline_notification",
      },
      {
        name: "inactive",
        key: "enable_inactive_notification",
      },
      {
        name: "fire Alarm (Email)",
        key: "enable_fire_alarm",
      },
      {
        name: "fire Alarm (SMS)",
        key: "enable_fire_alarm_sms",
      },
      {
        name: "pickup event (Email)",
        key: "enable_pickup_event",
      },
      {
        name: "pickup event (SMS)",
        key: "enable_pickup_event_sms",
      },
      {
        name: "temper event (Email)",
        key: "enable_temper_event",
      },
      {
        name: "temper event (SMS)",
        key: "enable_temper_event_sms",
      },
      {
        name: "Actions",
        key: "id",
      },
    ]);

    const total = ref<number>(0);
    const pagination = ref<IPagination>({
      page_number: 1,
      customer_id: "",
      page_size: 10,
      filters: {
        name: "",
      },
    });
    const getId = (v, name) => {
      data.value.id = v;
      data.value.company_name = name;
    };
    onMounted(async () => {
      customers.value = await getCustomersList();
      console.log("loading data", loadData());
      setCurrentPageBreadcrumbs("Notifications", [""]);
      permissions.value = localStorage.getItem("permissions");
      permissions.value = JSON.parse(permissions.value);
    });
    const loadData = async () => {
      loading.value = true;
      let binValue = await getNotificationListing(pagination.value);
      notification.value = binValue.notificaitons;
      total.value = binValue.total_number_of_records;
      loading.value = false;
    };

    const currentChange = (val) => {
      pagination.value.page_number = val;
      loadData();
    };
    const onCustomerChange = (event) => {
      pagination.value.page_number = 1;
      pagination.value.customer_id = event.target.value;
      loadData();
    };

    const getCustomerName = (val) => {
      let name = customers.value.filter((x) => (x.id = val))[0].name;
      return name;
    };

    const searchItems = () => {
      pagination.value.page_number = 1;
      if (search.value !== "") {
        pagination.value.filters.name = search.value;
      } else {
        pagination.value.filters.name = "";
      }
      loadData();
    };

    const itemsPerPageChange = (val) => {
      pagination.value.page_size = val;
      loadData();
    };
    const delete_notification = async (val) => {
      Swal.fire({
        title: "Are you sure you want to delete?",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          const resp = await deleteNotification(val);
          console.log(resp);
          loadData();
        }
      });
    };

    return {
      notification,
      data,
      loadData,
      headerConfig,
      customers,
      total,
      loading,
      getId,
      search,
      pagination,
      cardData,
      currentChange,
      searchItems,
      getCustomerName,
      itemsPerPageChange,
      onCustomerChange,
      delete_notification,
      permissions,
    };
  },
});
